import ajax from '@/utils/ajax.js'

/**
 * 获取oss配置
 */
 export function getOSSConf(params) {
  return ajax.post('/api/getOSSConf',params)
}

/**
 * 获取小程序配置
 */
export function transferAppletAuth(params) {
  return ajax.post('/api/transferAppletAuth',params)
}

// 获取律师函信息
export function getLawyer (params) {
  return ajax.post('/api/lawyer', params)
}

export function contractInfo(params) {
  return ajax.postRes('api/contractInfo', params)
}